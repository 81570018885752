/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;

/* HTML: <div class="wavy-line"></div> */
.wavy-line {
  --s: 0.35rem; /* size of the wave */
  --b: 0.1rem; /* thickness of the line */
  --m: 1.7; /* curvature of the wave [0 2] */

  background: theme(colors.cherry.DEFAULT);
  --R: calc(var(--s) * sqrt(var(--m) * var(--m) + 1) + var(--b) / 2);
  height: calc(2 * var(--R));
  width: 100%;
  --_g: #0000 calc(99% - var(--b)), #000 calc(101% - var(--b)) 99%, #0000 101%;
  mask:
    radial-gradient(
        var(--R) at left 50% bottom calc(-1 * var(--m) * var(--s)),
        var(--_g)
      )
      calc(50% - 2 * var(--s)) calc(50% - var(--s) / 2 - var(--b) / 2) /
      calc(4 * var(--s)) calc(var(--s) + var(--b)) repeat-x,
    radial-gradient(
        var(--R) at left 50% top calc(-1 * var(--m) * var(--s)),
        var(--_g)
      )
      50% calc(50% + var(--s) / 2 + var(--b) / 2) / calc(4 * var(--s))
      calc(var(--s) + var(--b)) repeat-x;
}
@tailwind utilities;
